import React, {ReactNode, useEffect} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';
import {RouteComponentProps} from 'react-router';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import {PRESS_ALIAS} from '../../constants/constants';
import {useAddToHomeScreen} from '../../hooks/useAddToHomeScreen';
import {useAudio} from '../../hooks/useAudio';
import {ICategory, IMusicTop, IPlayerInfo, Languages} from '../../types/types';
import {AddToHomeScreen} from '../AddToHomeScreen/AddToHomeScreen';
import {CategoryPage, ICategoryPageRouteProps} from '../CategoryPage/CategoryPage';
import {ContentPageContainer, IContentPageRouteProps} from '../ContentPage/ContentPageContainer';
import {ErrorPage} from '../ErrorPage/ErrorPage';
import {FooterContainer} from '../Footer/FooterContainer';
import {MainPage} from '../MainPage/MainPage';
import {OfferPageContainer} from '../OfferPage/OfferPageContainer';
import {Player} from '../Player/Player';
import {HorizontalPreloader} from '../Preloader/HorizontalPreloader';
import {
    IPressContentPageRouteProps,
    PressContentPageContainer,
} from '../Press/PressContentPage/PressContentPageContainer';
import {SubscriptionPageContainer} from '../SubscriptionPage/SubscriptionPageContainer';

import './App.css';

export interface IAppStateProps {
    datas: {
        [lang: string]: ICategory[] | [] | undefined;
    }
    data: ICategory[] | [] | undefined;
    musicData: ICategory[] | [];
    musicTops: IMusicTop[] | [];
    playerInfo: IPlayerInfo;
    categoryLoaded: boolean;
    error?: boolean;
    textLoaded: boolean;
    authorised?: boolean;
    lang: Languages;
}

export interface IAppDispatchProps {
    onInit(lang: Languages): void;
    onChange(lang: Languages): void;
}

export interface IAppProps extends IClassNameProps, IAppStateProps, IAppDispatchProps {
}

const app: ClassNameFormatter = cn('App');

export const App = (props: IAppProps) => {
    const { error, categoryLoaded, data, lang, datas, onChange } = props;

    const [, handlePlay, handlePause] = useAudio();

    const { prompt, promptToInstall } = useAddToHomeScreen();

    useEffect(() => {
        props.datas[lang] === undefined &&
        props.onInit(lang);
    }, [lang]);

    const renderMainPage = (properties: RouteComponentProps): ReactNode => {
        if (!categoryLoaded) {
            return (<HorizontalPreloader/>);
        } else if (error || !categoryLoaded || data && data[0] === undefined) {
            return <ErrorPage/>
        } else if (categoryLoaded && datas[lang] !== undefined) {
            return (
                <MainPage
                    categoriesData={data}
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                />
            )
        }
        return (<HorizontalPreloader/>);
    };

    const renderCategoryPage = (properties: RouteComponentProps<ICategoryPageRouteProps>): ReactNode => {
        if (error && !categoryLoaded) {
            return <ErrorPage/>
        } else if (data !== undefined && categoryLoaded) {
            return (
                <CategoryPage {...properties} categoriesData={data}/>
            )
        } else if (!categoryLoaded) {
            return (<HorizontalPreloader/>);
        }
        return (<HorizontalPreloader/>);
    };

    const renderContentPage = (properties: RouteComponentProps<IContentPageRouteProps>): ReactNode => {
        if (error && !categoryLoaded) {
            return <ErrorPage/>
        } else if (data !== undefined && categoryLoaded) {
            return (
                <ContentPageContainer {...properties} categoriesData={data} onLoad={() => {
                }} onCheck={() => {
                }}/>
            )
        } else if (!categoryLoaded) {
            return (<HorizontalPreloader/>);
        }
        return (<HorizontalPreloader/>);
    };

    const renderPressContentPage = (properties: RouteComponentProps<IPressContentPageRouteProps>): ReactNode => {
        if (data) {
            return (
                <PressContentPageContainer {...properties} categoriesData={data} onLoad={() => {
                }}/>
            )
        } else {
            return null;
        }
        return (<HorizontalPreloader/>);
    };

    const redirectToOffer = () => {
        let languageFromUrl: Languages | undefined;

        switch (location.pathname) {
            case '/offerru':
                languageFromUrl = Languages.ru;
                break;
            case '/offeren':
                languageFromUrl = Languages.en;
                break;
            case '/offeram':
                languageFromUrl = Languages.am;
                break;
            case 'offer':
            default:
                languageFromUrl = lang;
        }

        if (lang !== languageFromUrl) {
            onChange(languageFromUrl);
        }

        return (<Redirect to={'/offer'} />)
    };

    const renderOffer = (): ReactNode => {
        return (
            <OfferPageContainer onLoad={(language: Languages) => {
            }} categoriesData={data}/>
        )
    };

    const renderFooter = (): ReactNode => {
        if (data) {
            return (
                <FooterContainer onLoad={(language: Languages) => {
                }} categoriesData={data}/>
            )
        } else {
            return null;
        }
    };

    const renderSubscription = (): ReactNode => {
        return (
            <SubscriptionPageContainer
                onLoad={(language: Languages) => {
                }}
                categoriesData={data}
            />
        )
    };

    // const lastUrl: string | undefined = Cookies.get('lastUrl');
    // const redirect = lastUrl ? <Redirect from='/' to={lastUrl}/> : null;
    return (
        <div className={app()} key={lang}>
            <BrowserRouter>
                <div className={app('Wrapper')}>
                    {/*{authorised && redirect}*/}
                    <Switch>
                        <Route exact={true} path={'/'}
                               render={(properties: RouteComponentProps): ReactNode => {
                                   return renderMainPage(properties);
                               }}/>

                        <Route exact={true} path='/category/:alias'
                               render={(properties: RouteComponentProps<ICategoryPageRouteProps>): ReactNode => {
                                   return renderCategoryPage(properties);
                               }}/>
                        <Route exact={true} path={`/category/${PRESS_ALIAS}/content/:id`}
                               render={(properties: RouteComponentProps<IPressContentPageRouteProps>): ReactNode => {
                                   return renderPressContentPage(properties);
                               }}/>
                        <Route exact={true} path='/category/:alias/content/:id'
                               render={(properties: RouteComponentProps<IContentPageRouteProps>): ReactNode => {
                                   return renderContentPage(properties);
                               }}/>
                        <Route path='/subscription'
                               render={(): ReactNode => {
                                   return renderSubscription();
                               }}/>
                    </Switch>
                    <Switch>
                        <Route path={`/category/${PRESS_ALIAS}/content/:id`} render={(): null => {
                            return null;
                        }}/>
                        <Route path='*'
                               render={(): ReactNode => {
                                   return renderFooter();
                               }}/>
                    </Switch>
                    <Player
                        handlePlay={handlePlay}
                        handlePause={handlePause}
                    />
                    <AddToHomeScreen
                      prompt={prompt}
                      promptToInstall={promptToInstall}
                    />
                </div>
            </BrowserRouter>
        </div>
    );
}
