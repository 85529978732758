import React, {Component, Fragment} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {COMMON_CONTENT_CARD_TYPE} from '../../constants/constants';
import {IContentCard} from '../../helpers/contentHelper';
import {ContentItemPreviewContainer} from '../ContentItemPreview/ContentItemPreviewContainer';
import {PromoBanner} from '../PromoBanner/PromoBanner';

import './ContentListFull.css';

export interface IContentListFull extends IClassNameProps {
    cards: IContentCard[];
    alias: string;
    page: string;
}

const contentListFull: ClassNameFormatter = cn('ContentListFull');

export class ContentListFull extends Component <IContentListFull> {

    getContentItems = (content: IContentCard[]) => {
        const {alias} = this.props;

        return (
            content.map((contentItem: IContentCard, index) => {
                const size: string = (index) % 8 > 3 ? 'small' : 'big';
                const {cardType, data} = contentItem;
                if (cardType === COMMON_CONTENT_CARD_TYPE) {
                    const [contentData] = data;
                    const bannerIndex = 3;
                    const contentItemPreview = (
                      <ContentItemPreviewContainer
                        className={contentListFull('Item', {size})}
                        id={contentData.id}
                        key={contentData.id}
                        title={contentData.name}
                        description={contentData.description}
                        tags={contentData.tags}
                        order={index}
                        previews={contentData.previews}
                        type={contentData.type}
                        url={contentData.file_hash}
                        categoryAlias={alias}
                        page={this.props.page}
                        uidx={contentData.uidx}
                        size={size}
                      />
                    )
                    if (index === bannerIndex) {
                      return (
                        <Fragment key={contentData.id}>
                          {contentItemPreview}
                          <PromoBanner />
                        </Fragment>
                      )
                    } else {
                      return contentItemPreview
                    }
                }
            })
        )
    };

    render() {
        const {cards} = this.props;

        return (
            <div className={contentListFull()}>
                <div className={contentListFull('ItemsWrapper')}>
                    {this.getContentItems(cards)}
                </div>
            </div>
        )
    }
}
