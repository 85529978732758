import React from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';

import { ReactComponent as PromoLogoIcon } from '../Icons/promo-logo.svg';
import { PromoBannerTypes } from '../PromoBanner/PromoBanner';

import './PromoBannerItem.css'

export interface IPromoBanner {
  image: string,
  promoBannerTypes?: PromoBannerTypes
}

export function PromoBannerItem(props: IPromoBanner) {
  const promoBanner: ClassNameFormatter = cn('PromoBannerItem');

  const { image, promoBannerTypes } = props;
  const isLongType = promoBannerTypes === PromoBannerTypes.Long;
  const isLightType = promoBannerTypes === PromoBannerTypes.Light;

  return (
    <div className={promoBanner()}>
      <div className={promoBanner('Logo')}>
        <PromoLogoIcon />
      </div>
      <div className={classnames(promoBanner('Caption', {Long: isLongType, Light: isLightType}))}>
        <span>
          Онлайн-журнал
          {promoBannerTypes === PromoBannerTypes.Long ? ' ' : <br />}
          №1 для мужчин
        </span>
      </div>
      <div className={promoBanner('ImageWrap')}>
        <img src={image} alt='' className={promoBanner('Image')} />
      </div>
    </div>
  );
}
