import { useEffect, useState } from 'react';

interface IBeforeInstallPromptEvent extends Event {
    readonly platforms: string[]
    readonly userChoice: Promise<{ outcome: 'accepted' | 'dismissed', platform: string }>
    prompt(): Promise<void>
}
export interface IUseAddToHomeScreen {
    prompt: IBeforeInstallPromptEvent | null
    promptToInstall(): void;
}

export function useAddToHomeScreen(): IUseAddToHomeScreen {
    const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null);

    const promptToInstall = () => {
        if (prompt) {
            return prompt.prompt();
        }
        return Promise.reject(new Error('Tried installing before browser sent "beforeinstallprompt" event'));
    };

    useEffect(() => {
        const ready = (e: IBeforeInstallPromptEvent) => {
            e.preventDefault();
            setPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', ready as any);
        return () => {
            window.removeEventListener('beforeinstallprompt', ready as any);
        };
    }, []);

    return { prompt, promptToInstall };
}
