import React, { useEffect, useMemo, useState } from 'react';

import { ClassNameFormatter, cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { checkIsInStandaloneMode, checkIsIOS } from '../../helpers/platform';
import { IUseAddToHomeScreen } from '../../hooks/useAddToHomeScreen';
import { ReactComponent as IOSMenuIcon } from '../Icons/ios-menu.svg';
import { ReactComponent as DownloadIcon } from '../Icons/pwa-download.svg';
import { ReactComponent as XCircleIcon } from '../Icons/x-circle.svg';
import { ReactComponent as XIcon } from '../Icons/x.svg';

import './AddToHomeScreen.css'

type IAddToHomeScreeProps = IUseAddToHomeScreen;

export function AddToHomeScreen(props: IAddToHomeScreeProps) {
  const { prompt, promptToInstall } = props;

  const addToHomeScreen: ClassNameFormatter = cn('AddToHomeScreen');

  const isLaptop = useMediaQuery({ query: '(min-width: 1024px)' });

  const { t } = useTranslation()

  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [canClose, setCanClose] = useState<boolean>(false);

  const canInstallApp = !!prompt;
  const isIOS = checkIsIOS();
  const isInStandaloneMode = checkIsInStandaloneMode();
  const canShowModal = canInstallApp || (isIOS && !isInStandaloneMode);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setShow(false);
    const timeSec = 15 * 60 * 1000;
    setTimeout(() => handleShow(), timeSec);
  };
  const handleDismissed = () => {
    setOpen(false);
    setCanClose(true);
  }
  const handleInstall = () => {
    promptToInstall();
    setTimeout(() => {
      if (prompt) {
        prompt.userChoice
          .then((res) => {
            if (res.outcome === 'accepted') {
              handleHide()
            } else {
              handleDismissed();
            }
          })
          .catch(() => {
            handleDismissed();
          })
      }
    }, 0)
  };
  const handlePopupToggle = () => {
    setOpen(!open);
    setCanClose(open);
  };

  useEffect(() => {
    if (canShowModal) {
      handleShow();
    }
  }, [canShowModal]);

  const imgSrc = `${process.env.PUBLIC_URL}/img/icon-192x192.png`;

  const buttonEl = (
    <div
      className={classnames(
        addToHomeScreen('Button'),
        open ? undefined : addToHomeScreen('Button-Animate'),
      )}
      onClick={handlePopupToggle}
    >
      <DownloadIcon />
      {canClose ? (
        <div
          className={addToHomeScreen('ButtonClose')}
          onClick={(ev) => {
            ev.stopPropagation();
            handleHide();
          }}
        >
          <XCircleIcon />
        </div>
      ) : null}
    </div>
  );

  const serviceName = 'Men`s live';
  const homePageName = useMemo(() => (isLaptop ? t('на рабочий стол?') : t('на домашний экран?')), [isLaptop]);
  const installAppText = `${t('Добавить ярлык')} «${serviceName}» ${homePageName}`;
  const iOSAppText = (
    <>
      {installAppText}
      <br />
      {t('Нажмите на')}
      <IOSMenuIcon />
      {t('и выберите «На экран Домой»')}
    </>
  );
  const text = useMemo(() => (canInstallApp ? installAppText : iOSAppText), [canInstallApp]);

  const popupEl = open ? (
    <div className={addToHomeScreen('Popup')}>
      <div className={addToHomeScreen('PopupClose')} onClick={handlePopupToggle}>
        <XIcon />
      </div>
      <img className={addToHomeScreen('PopupCover')} src={imgSrc} alt={''} />
      <div className={addToHomeScreen('PopupContent')}>
        <div className={addToHomeScreen('PopupText')}>{text}</div>
        {canInstallApp ? (
          <div className={addToHomeScreen('PopupButton')} onClick={handleInstall}>
            {t('Добавить')}
          </div>
        ) : null}
      </div>
    </div>
  ) : null;

  if (show && (canInstallApp || isIOS)) {
    return (
      <div className={addToHomeScreen()}>
        {popupEl}
        {buttonEl}
      </div>
    );
  }

  return null;
}
